
import logo from './logo.png';
import './App.css';
import './index.css';
import './index.portrait.css';
import './index.landscape.css';

function App() {
  return ;
}

export default App;
